import { defineStore } from 'pinia'
import { errorStore } from './error'
import { userStore } from './user'

export const dataStore = defineStore('data', {
  state: () => ({
    errorStore: errorStore(),
    lastRefresh: '1999-06-09 06:09:99',
    selectedCourse: {
      courseDetails: {},
      ratingData: null,
      timetableFilter: null,
      historyData: null,
      timetable: null
    },
    selectedProf: null,
    searchQuery: null,
    treeWarning: true,
    timetableBetaWarning: true,
    courseList: [],
    timetableCourses: [],
    directoryState: {
      directory: null,
      searchQuery: null,
      retainQuery: true,
      filters: [],
      sortAndPageInfo: null
    },
    filterState: {
      filters: null,
      current: []
    },
    ratingOptions: {
      tags: [],
      professors: []
    }
  }),
  actions: {
    setLastRefresh (date) {
      if (date?.lastRefresh) this.lastRefresh = date.lastRefresh
    },
    setSelectedProf (prof) { this.selectedProf = prof },
    setSelectedCourseDetails (details) { this.selectedCourse.courseDetails = details },
    setRatingOoptionsTags (tags) { this.ratingOptions.tags = tags },
    setRatingsOptionsProfessors (profs) { this.ratingOptions.professors = profs },
    resetSelectedFilters () {
      this.filterState.filters.standard.forEach(filter => { filter.selected = [] })
    },
    setCurrentFilterState (filters) { this.filterState.current = filters },
    setFilters (filters) { this.filterState.filters = filters },

    setSelectedCourse (key, value) {
      this.selectedCourse[key] = value
    },

    setDirectoryStateBykey (key, value) {
      this.directoryState[key] = value
    },
    resetDirectoryState () { this.directoryState.directory = null },
    resetDirectoryRetainQuery () { this.directoryState.retainQuery = null },

    resetSelectedCourse () {
      this.selectedCourse.courseDetails = {}
      this.selectedCourse.historyData = null
    },
    addTimetableEntry (course) {
      this.timetableCourses.push(course)
      this._persistTimetableCart()
    },
    deleteTimetableEntry (deletion) {
      this.timetableCourses.splice(this.timetableCourses.findIndex((course) => {
        return course.data.courseResult.code === deletion[0] && course.lecture.section[0] === deletion[1]
      }), 1)
      this._persistTimetableCart()
    },
    saveTimetableEntry (courses) {
      this.timetableCourses = courses
      this._persistTimetableCart()
    },
    setTimetableCart (cart) {
      this.timetableCourses = cart
      this._persistTimetableCart()
    },
    clearTimetableEntry (semester) {
      if (semester) {
        let idx
        for (idx = this.timetableCourses.length - 1; idx >= 0; idx -= 1) {
          if (this.timetableCourses[idx].lecture.section.slice(0, 1) === semester) {
            this.timetableCourses.splice(idx, 1)
          }
        }
      } else {
        this.timetableCourses = []
      }

      this._persistTimetableCart()
    },
    async loadCourseList (campus) {
      const q = {
        query: 'query getCourseList ($filters: [JSON], $current: Boolean!) { getCourseList (filters: $filters, current: $current) { code, name } }',
        variables: { filters: [{ selected: [campus], value: 'campus' }], current: false },
        operationName: 'getCourseList'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })

        const graphQlRes = await response.json()

        if (graphQlRes.data) {
          this.courseList.push(...graphQlRes.data.getCourseList.map(course => course.code + ': ' + course.name))
        } else {
          // TODO: Update error store...
          // state.errors.graphQL = graphQlRes.errors[0].message
        }
      } catch (error) {
        // TODO: Update error store...
        // state.errors.graphQL = error
      }
    },
    async loadUserCourses () {
      const { userInfo } = userStore()
      if (!userInfo) return

      userInfo.data.checked = false
      const q = {
        query: 'query getUserCourses { getUserCourses { code, course, field, breadth, bird, taken, rated } }',
        operationName: 'getUserCourses'
      }

      try {
        const response = await fetch('/graphql', {
          method: 'post',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify(q)
        })

        const graphQlRes = await response.json()

        if (graphQlRes.data) {
          userInfo.data.courses = graphQlRes.data.getUserCourses
          userInfo.data.checked = true
        } else {
          // TODO: Update error store...
          // state.errors.graphQL = graphQlRes.errors[0].message
        }
      } catch (error) {
        // TODO: Update error store...
        // state.errors.graphQL = error
      }
    },
    _persistTimetableCart () {
      if (this.timetableCourses.length > 0) {
        localStorage.setItem('timetable-cart', JSON.stringify(this.timetableCourses))
      } else {
        localStorage.removeItem('timetable-cart')
      }
    }
  }
})
